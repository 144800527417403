.float-button {
  width: 1.28rem;
  height: 7.093333rem;
}

.float-button .float-wrapper {
  height: auto;
  border-radius: 0.64rem;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0 0.106667rem 0 rgba(120,120,120,0.3);
}

.float-button .float-wrapper>.float-item {
  margin-top: 0.533333rem 0;
}
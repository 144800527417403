@font-face {
  font-family: 'iconfont';
  src: url("//at.alicdn.com/t/font_2013799_8y1fhhnxh62.eot");
  src: url("//at.alicdn.com/t/font_2013799_8y1fhhnxh62.eot?#iefix") format("embedded-opentype"),url("//at.alicdn.com/t/font_2013799_8y1fhhnxh62.woff2") format("woff2"),url("//at.alicdn.com/t/font_2013799_8y1fhhnxh62.woff") format("woff"),url("//at.alicdn.com/t/font_2013799_8y1fhhnxh62.ttf") format("truetype"),url("//at.alicdn.com/t/font_2013799_8y1fhhnxh62.svg#iconfont") format("svg");
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 0.426667rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.005333rem;
  -moz-osx-font-smoothing: grayscale;
}

.drawer .head-row {
  width: 100%;
  text-align: left;
  background-color: #efeff4;
  padding-left: 0.1rem;
  height: 0.8rem;
  line-height: 0.8rem;
}

.drawer .head-row i {
  color: #1890ff;
  font-size: 0.6rem;
}

.drawer .input-wrapper {
  width: 90%;
  margin: 0.8rem auto 0 auto;
  border: 0.026667rem solid #e0e0e0;
  text-align: left;
  border-radius: 0.1rem;
  padding: 0.1rem 0.1rem 0.05rem 0.1rem;
  display: flex;
  align-items: center;
}

.drawer .input-wrapper .icon {
  padding: 0 0.1rem;
}

.drawer .input-wrapper .icon i {
  color: #1890ff;
  font-size: 0.5rem;
}

.drawer .input-wrapper .input {
  width: 100%;
}

.drawer .input-wrapper .input input::input-placeholder {
  text-align: center;
}

.drawer .input-wrapper .input input {
  height: 0.7rem;
  width: 90%;
  border: 0;
  font-size: 0.4rem;
}

.drawer .input-lable {
  color: #333333;
  font-size: 0.32rem;
  width: 90%;
  margin: 0.5rem auto 0 auto;
  text-align: left;
  font-weight: 600;
}

.drawer .select-wrapper {
  width: 90%;
  margin: 0.5rem auto 0 auto;
  border: 0.026667rem solid #e0e0e0;
  text-align: left;
  border-radius: 0.1rem;
  padding: 0rem 0.1rem;
  display: flex;
  align-items: center;
  min-height: 0.8rem;
}

.drawer .select-wrapper .input {
  width: 100%;
  text-align: left;
  font-size: 0.4rem;
  padding: 0.1rem 0 0.1rem 0.2rem;
}

.drawer .select-wrapper .icon {
  padding: 0 0.1rem;
}

.drawer .select-wrapper .icon i {
  color: #e0e0e0;
  font-size: 0.5rem;
}

.drawer .work-year-wrapper {
  width: 90%;
  margin: 0.5rem auto 0 auto;
  display: flex;
  justify-content: space-between;
}

.drawer .work-year-wrapper .year-item {
  border-radius: 0.1rem;
  flex: 2;
  border: 0.03rem solid #e0e0e0;
  display: flex;
  height: 0.8rem;
  line-height: 0.8rem;
  padding-left: 0.2rem;
}

.drawer .work-year-wrapper .year-item .input {
  width: 100%;
  text-align: left;
  font-size: 0.4rem;
  display: inline-block;
  vertical-align: middle;
}

.drawer .work-year-wrapper .year-item .icon {
  padding: 0 0.1rem;
  display: inline-block;
  vertical-align: middle;
}

.drawer .work-year-wrapper .year-item .icon i {
  color: #e0e0e0;
  font-size: 0.5rem;
}

.drawer .work-year-wrapper .center-gap {
  font-size: 0.4rem;
  height: 0.8rem;
  line-height: 0.8rem;
  color: #333333;
  flex: 1;
  text-align: center;
}

.drawer .educate-wrapper {
  width: 90%;
  margin: 0.5rem auto 0 auto;
  display: flex;
  justify-content: space-between;
}

.drawer .educate-wrapper .educate-item {
  border-radius: 0.1rem;
  flex: 2;
  border: 0.03rem solid #e0e0e0;
  display: flex;
  height: 0.8rem;
  line-height: 0.8rem;
  padding-left: 0.2rem;
}

.drawer .educate-wrapper .educate-item .input {
  width: 100%;
  text-align: left;
  font-size: 0.4rem;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}

.drawer .educate-wrapper .educate-item .icon {
  padding: 0 0.1rem;
  display: inline-block;
  vertical-align: middle;
}

.drawer .educate-wrapper .educate-item .icon i {
  color: #e0e0e0;
  font-size: 0.5rem;
}

.drawer .educate-wrapper .center-gap {
  font-size: 0.4rem;
  height: 0.8rem;
  line-height: 0.8rem;
  color: #333333;
  flex: 1;
  text-align: center;
}

.drawer .bottom-row {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  font-size: 0.4rem;
}

.drawer .bottom-row div {
  flex: 1;
  padding: 0.45rem 0;
  cursor: pointer;
}

.drawer .bottom-row .left {
  text-align: center;
  background-color: #efeff4;
  color: #333333;
}

.drawer .bottom-row .right {
  text-align: center;
  background-color: #1890ff;
  color: #ffffff;
}